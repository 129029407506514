































































































































































































































import Vue from "vue";
import {OPPoll, StreamKeyData, StreamParticipant} from '@/types/stream';
import { Component } from 'vue-property-decorator'
import ChatList from "@/components/ChatList.vue";
import ChatWindow from "@/components/ChatWindow.vue";
import {Notify} from "@/util/notification";
import {TitleManager} from "@/util/titleManager";
import { getIdFromURL, getTimeFromURL } from 'vue-youtube-embed'
import {idToColor} from "@/util/colorlib";

@Component({
    components: {
        ChatList,
        ChatWindow
    }
})
export default class WatchStream extends Vue {
    loading = true;
    rightnav = false;
    invitationKey = "";
    chatOpen = false;
    notFound = false;
    notAuthorized = false;
    playerHeight = 400;
    firstname = "";
    lastname = "";
    email = "";
    operatorTab = 0;
    currentAnswer = null;
    chatAllDialog = false;
    chatAllText = "";
    messageToFeature: null | any = null;

    //chatParticipant: ChatUser | null = null;

    get authorized() { return this.$store.getters['stream/authorized']; }

    get chatRooms() { return this.$store.getters['chat/getRooms']; }

    get currentRoom() { return this.$store.getters['chat/getCurrentRoom']; }

    get isOperator() { return this.$store.getters['stream/isOperator']; }

    get streamData() { return this.$store.getters['stream/streamData']; }

    get snackbar() {
        return this.$store.getters['message/getSnackbar'];
    }

    get hasSnackbar() {
        return this.$store.getters['message/hasSnackbar'];
    }

    async hideSnackbar() {
        return await this.$store.dispatch('message/hideSnackbar');
    }

    get polls() { return this.$store.getters['poll/polls']; }

    get currentPoll() { return this.$store.getters['poll/currentPoll'];}

    async launchPoll(poll: OPPoll) {
        await this.$store.dispatch('poll/launch', poll);
    }

    async clearPoll() {
        await this.$store.dispatch('poll/clear');
    }

    async votePoll() {
        await this.$store.dispatch('poll/vote', this.currentAnswer);
        this.currentAnswer = null;
    }

    setPlayerHeight() {
        if (this.$refs.player) {
            const rect = (this.$refs.player as Vue).$el.getBoundingClientRect();
            this.playerHeight = rect.width / 16 * 9;
        }
    }

    getYTId(url) {
        return getIdFromURL(url);
    }

    async sendTextToAll() {
        await this.$store.dispatch('chat/sendMessageToEveryone', this.chatAllText);
        this.chatAllText = "";
        this.chatAllDialog = false;
    }

    async featureMessage() {
        if (this.messageToFeature !== null) {
            await this.$store.dispatch('chat/featureMessage', this.messageToFeature);
            this.messageToFeature = null;
        }
    }

    async openChat(chat) {
        await this.$store.dispatch('chat/openRoom', chat);
        this.chatOpen = true;
    }

    async closeChat() {
        this.chatOpen = false;
        await this.$store.dispatch('chat/openRoom', null);
    }

    closeWindow() {
        window.location.replace("https://www.google.nl/");
    }

    getStreamKey() {
        if (this.$route.query.key)
            return this.$route.query.key;
        if (localStorage.getItem('keydata')) {
            const keyData: StreamKeyData = JSON.parse(localStorage.getItem('keydata')!);
            if (keyData.streamId == this.$route.params.stream)
                return keyData.key;
        }
        return null;
    }

    avatarColor(id: string): string {
        return idToColor(id);
    }

    async mounted() {
        let key = this.getStreamKey();
        if (key) {
            await this.$store.dispatch("stream/authorize", {key, streamId: this.$route.params.stream});
            this.rightnav = true;
            this.setPlayerHeight();
        }
        let resp = await this.$store.dispatch("stream/loadStreamData", {streamId: this.$route.params.stream});
        if (resp == null) {
            this.notFound = true;
            return;
        } else if (!this.streamData.isPublic && !key) {
            this.notAuthorized = true;
            return;
        }

        this.loading = false;
        TitleManager.setBaseTitle("Streaming: " + this.streamData.title);
    }

    async joinAsNewParticipant() {
        let res = await this.$store.dispatch("stream/joinAsNewParticipant", {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            streamId: this.$route.params.stream
        });
        if (res) {
            this.rightnav = true;
            this.setPlayerHeight();
        }
    }
}
