import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

export type SnackbarOptions = {
    message: string,
    timeout?: number,
    color: string
};

@Module({namespaced: true})
export default class MessageModule extends VuexModule {
    snackbar: SnackbarOptions | null = null;

    @Mutation
    setSnackbar(opts: SnackbarOptions | null) {
        this.snackbar = opts;
    }

    @Action({rawError: true})
    showSnackbar(opts: SnackbarOptions) {
        this.context.commit('setSnackbar', opts);
    }

    @Action({rawError: true})
    hideSnackbar() {
        this.context.commit('setSnackbar', null);
    }

    get getSnackbar() {
        return this.snackbar;
    }

    get hasSnackbar() {
        return this.snackbar != null;
    }
}
