




















































import Vue from "vue";
import Component from "vue-class-component";
import DashboardLayout from "../../layouts/DashboardLayout.vue";
import ServerSidedTable from "@/components/ServerSidedTable.vue";
import StreamForm from "@/components/forms/StreamForm.vue";
import {http} from "@/plugins/axios";
import {Stream} from "@/types/acp";
@Component({
    components: {StreamForm, ServerSidedTable, DashboardLayout}
})
export default class Dashboard extends Vue {
    items = [];
    newDialog = false;
    newLoading = false;
    newStream: Stream | null = null;
    deleteItem: any = null;
    headers = [
        {text: "Stream", value: 'title'},
        {text: "Aantal uitnodigingen", value: 'participant_count'},
        {text: "Stream link", value: 'url'},
        {text: "Acties", value: 'actions'},
    ];

    $refs!: {
        table: ServerSidedTable
    };

    closeDialog() {
        this.newStream = {};
        this.newDialog = false;
    }

    async submit() {
        this.newLoading = true;
        let resp = await http.post('admin/streams', this.newStream);
        this.newLoading = false;

        await this.$router.push({name: 'ViewStream', params: {stream: resp.data.id}});
    }

    async browseTo(item) {
        await this.$router.push({name: 'ViewStream', params: {stream: item.id}});
    }

    async doDelete() {
        await http.post(`admin/streams/${this.deleteItem.id}`, {_method: "DELETE"});
        await this.$refs.table.refresh();
        this.deleteItem = null;
    }
}
