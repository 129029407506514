import axios from "axios";
import store from "@/store/index";

const _axios = axios.create({
    baseURL: "/"
});

_axios.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    if (error.response?.status === 401) {
        await store.dispatch('message/showSnackbar', {
            color: "red",
            timeout: 5000,
            message: "Toegang geweigerd."
        });
    } else if (error.response?.status === 500) {
        await store.dispatch('message/showSnackbar', {
            color: "red",
            timeout: 5000,
            message: "Er is een fout opgetreden bij uw verzoek. Probeer het later nog eens."
        });
    }
    return Promise.reject(error.message);
});

export {_axios as http }
