
export class TitleManager {
    static baseTitle = "";
    static formatTitle = "";

    static setBaseTitle(baseTitle) {
        this.baseTitle = baseTitle;
        this.render();
    }

    static setFormat(format) {
        this.formatTitle = format;
        this.render();
    }

    static render() {
        const renderedTitle = `${this.formatTitle} ${this.baseTitle}`;
        if (document.title != renderedTitle)
            document.title = renderedTitle;
    }
}
