import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {http} from "@/plugins/axios";

export type AuthData = {
    token: string,
    user: any
}

export type LoginCredentials = {
    email: string,
    password: string
}

@Module({namespaced: true})
export default class AuthModule extends VuexModule {
    _user: AuthData | null = null;

    @Mutation
    setUserData(userData) {
        this._user = userData;
        localStorage.setItem('user', JSON.stringify(userData))
        http.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
    }

    @Mutation
    clearUserData() {
        localStorage.removeItem('user');
        location.reload();
    }

    @Action({commit: 'setUserData', rawError: true})
    async login(credentials: LoginCredentials) {
        const resp = await http.post('/login', credentials);
        return resp.data;
    }

    @Action({commit: 'clearUserData'})
    logout() {
        //
    }

    get user() { return this._user?.user; }
    get isLogged() { return !!this._user; }

}
