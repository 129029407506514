import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import {
    ChatMessage,
    Chatroom,
    ChatroomCreatedEventData,
    ChatroomUpdatedEventData,
    IncomingChatMessageEventData
} from "@/types/stream";
import {getEcho} from "@/plugins/echo";
import {http} from "@/plugins/axios";
import {Notify} from "@/util/notification";
import {TitleManager} from "@/util/titleManager";


@Module({namespaced: true})
export class ChatModule extends VuexModule {
    rooms: Chatroom[] = [];
    currentRoom: Chatroom | null = null;
    chatLoading = false

    @Mutation
    ADD_ROOM(data: ChatroomCreatedEventData) {
        this.rooms.push({
            id: data.room.id,
            participant: data.participant,
            state: {unread: 0, lastMessage: null}
        });
    }

    @Mutation
    UPDATE_ROOM_STATE(data: ChatroomUpdatedEventData) {
        const room = this.rooms.find(x => x.id == data.roomId);
        if (!room)
            console.error(`Room ${data.roomId} is not ours. Where did it come from?`);
        room!.state = data.state;
        if (this.currentRoom && this.currentRoom.id === data.roomId)
            this.currentRoom!.state = data.state;
        if (data.state.unread !== 0) {
            Notify();
            this.rooms.sort((a, b) => a == room ? -1 : 1);
        }
    }

    @Mutation
    INCOMING_CHAT_MESSAGE(data: IncomingChatMessageEventData) {
        this.currentRoom!.messages!.push(data.message);
    }

    @Mutation
    addChatMessage(data: ChatMessage) {
        this.currentRoom!.messages!.push(data);
    }

    @Mutation removeChatMessage(msg: ChatMessage) {
        this.currentRoom!.messages! = this.currentRoom!.messages!.filter(x => x !== msg);
    }

    @Mutation setLoading(loading: boolean) {
        this.chatLoading = loading;
    }

    @Mutation
    clearRooms() {
        this.rooms = [];
    }

    @Mutation
    addRoom(data) {
        this.rooms.push({
            id: data.id,
            participant: data.participant,
            state: data.state
        })
    }

    @Mutation
    setOpenRoom(room: Chatroom | null) {
        if (room !== null)
            this.currentRoom = {...room, messages: []};
        else
            this.currentRoom = null;
    }

    @Mutation
    setCurrentRoomMessages(messages: ChatMessage[]) {
        this.currentRoom!.messages = messages;
    }

    @Action
    async handleUnreadMessages() {
        const unread = this.context.getters['totalUnread'];
        if (unread > 0) {
            TitleManager.setFormat(`(${unread})`);
        } else
            TitleManager.setFormat('');
    }

    @Action
    async openRoom(room: Chatroom | null) {
        const curRoom: Chatroom = this.context.getters['getCurrentRoom'];
        if (curRoom !== null) {
            getEcho().leaveChannel(`chatroom.${curRoom.id}`);
            await this.context.dispatch('sendMessagesRead');
        }
        if (room !== null) {
            this.context.commit('setOpenRoom', room);
            const messages = await this.context.dispatch('fetchMessages', room);
            this.context.commit('setCurrentRoomMessages', messages);
            getEcho().channel(`chatroom.${room.id}`)
                .listen('NewChatMessage', (data) => {
                    this.context.commit('INCOMING_CHAT_MESSAGE', data);
                    this.context.dispatch('handleUnreadMessages');
                });

        } else
            this.context.commit('setOpenRoom', room);
        return room;
    }

    @Action
    async fetchMessages(room: Chatroom) {
        this.context.commit("setLoading", true);
        const pData = this.context.rootGetters['stream/authData'];
        const resp = await http.get(`chat/rooms/${room.id}/messages`);
        this.context.commit("setLoading", false);
        return resp.data.data;
    }

    @Action
    async sendMessage(message: string) {
        const msg: ChatMessage = {
            from: {
                name: "Verzenden...",
                id: "0",
                operator: false,
            },
            message,
            sending: true
        };
        this.context.commit('addChatMessage', msg);
        const pData = this.context.rootGetters['stream/authData'];
        const room = this.context.getters['getCurrentRoom'];
        const resp = await http.post(`chat/rooms/${room.id}/send`, {
            message
        });
        this.context.commit('removeChatMessage', msg);
    }

    @Action
    async sendMessagesRead() {
        const pData = this.context.rootGetters['stream/authData'];
        const room = this.context.getters['getCurrentRoom'];
        const resp = await http.post(`chat/rooms/${room.id}/read`);
    }

    @Action
    async sendMessageToEveryone(message: string) {
        const streamData = this.context.rootGetters['stream/streamData'];
        const resp = await http.post(`streams/${streamData.id}/chatall`, {message});
    }

    @Action
    async featureMessage(message: ChatMessage) {
        const streamData = this.context.rootGetters['stream/streamData'];
        const resp = await http.post(`streams/${streamData.id}/feature`, {message_id: message.id!});
    }

    @Action({rawError: true})
    async fetchRooms() {
        this.context.commit('clearRooms');
        const pData = this.context.rootGetters['stream/authData'];
        const resp = await http.get(`chat/rooms`);
        for(const room of resp.data.data) {
            this.context.commit('addRoom', room);
        }
        if (!pData.operator) {
            const streamId = this.context.rootGetters['stream/streamData'];
            const room: Chatroom | undefined = this.context.getters['getRooms'][0];
            await this.context.dispatch('openRoom', room);
        }
        await this.context.dispatch('handleUnreadMessages');
    }

    @Action({rawError: true})
    async init() {
        const data = this.context.rootGetters['stream/authData'];
        await this.context.dispatch('fetchRooms');
        getEcho()
            .channel(`participant.${data.id}`)
            .listen('ChatroomCreated', (data) => this.context.commit('ADD_ROOM', data))
            .listen('ChatroomStateUpdated', (data) => {
                this.context.commit('UPDATE_ROOM_STATE', data);
                this.context.dispatch('handleUnreadMessages');
            })
    }

    get getRooms() {
        return this.rooms;
    }

    get totalUnread() {
        let out = 0;
        for(const room of this.rooms)
            out += room.state.unread;
        return out;
    }

    get getCurrentRoom() {
        return this.currentRoom;
    }

    get loading() {
        return this.chatLoading;
    }
}

export default ChatModule;
