import colors from 'vuetify/lib/util/colors'

function shuffle(array: any, seed: number) {
    let m = array.length, t, i;

    // While there remain elements to shuffle…
    while (m) {

        // Pick a remaining element…
        i = Math.floor(random(seed) * m--);

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
        ++seed
    }

    return array;
}

function random(seed: number) {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
}

export function inverseTextColor(backgroundColor: string, whenDarkColor: string, whenLightColor: string) {
    const c = backgroundColor.substring(1);      // strip #
    const rgb = parseInt(c, 16);   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >>  8) & 0xff;  // extract green
    const b = (rgb >>  0) & 0xff;  // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    return luma > 128 ? whenLightColor : whenDarkColor;

}

function uuidToNumber(id: string) {
    id = id.replace(/-/g, "");
    let sum = 0;
    for (let i=0;i<id.length;i+=2) {
        sum += parseInt(id.substr(i, 2), 16);
    }
    return sum;
}

export function idToColor(id: string) {
    const idNum = uuidToNumber(id);
    const colorKeys = Object.keys(colors);
    const colorArr = shuffle(colorKeys, colorKeys.length);
    const i1 = idNum % colorArr.length;
    const k1 = colorArr[i1];

    const tintKeys = Object.keys(colors[k1]);
    const tintArr = shuffle(tintKeys, tintKeys.length);
    const i2 = (idNum - i1) % tintArr.length;
    const k2 = Object.keys(colors[k1])[i2];
    return colors[k1][k2];
}
