















































































import Vue from "vue";
import Component from "vue-class-component";
import ColorInput from "@/components/ColorInput.vue";
import CopyButton from "@/components/CopyButton.vue";
import {http} from "@/plugins/axios";

@Component({components: {ColorInput, CopyButton}})
export default class ElementsTab extends Vue {
    loading = false;
    textColor = "#000000";
    selectedType = "timer";
    timerTypesDropdown = [
        {
            key: "countdown",
            text: "Countdown"
        },
        {
            key: "timer",
            text: "Timer"
        }
    ];
    orientationTypesDropdown = [
        {
            key: 'bottom',
            text: 'Onder'
        },
        {
            key: 'top',
            text: 'Boven'
        },
        {
            key: 'static',
            text: 'Statisch'
        }
    ]
    pollDropdown: Array<any> = [];
    counterElement: any = null
    pollElement: any = null
    messageElement: any = null

    async fetch() {
        this.loading = true;
        const resp = await http.get(`/admin/streams/${this.$route.params.stream}/elements`);
        this.counterElement = resp.data.data.find(x => x.element === "counter");
        this.pollElement = resp.data.data.find(x => x.element === "poll");
        this.pollDropdown = resp.data.polls;
        this.messageElement = resp.data.data.find(x => x.element === "message");
        this.loading = false;
    }

    async save() {
        const data = [
            this.counterElement,
            this.pollElement,
            this.messageElement
        ];

        await http.post(`/admin/streams/${this.$route.params.stream}/elements`, {elements: data});
        await this.$store.dispatch('message/showSnackbar', {color: 'success', message: 'Gegevens zijn opgeslagen'});
    }

    async startCountdown() {
        this.counterElement.properties.started = true;
        await this.save();
    }

    async stopCountdown() {
        this.counterElement.properties.started = false;
        await this.save();
    }

    async showPoll() {
        this.pollElement.properties.show = true;
        await this.save();
    }

    async hidePoll() {
        this.pollElement.properties.show = false;
        await this.save();
    }

    async mounted() {
        await this.fetch();
    }
}
