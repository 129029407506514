



































































































import Vue from "vue";
import Component from "vue-class-component";
import {http} from "@/plugins/axios";
import Sortable from 'sortablejs';
import DeleteButton from "@/components/DeleteButton.vue";
@Component({
    components: {DeleteButton}
})
export default class PollTab extends Vue {
    pollId: number | null = null;
    question = "";
    options = [""];
    showEditor = false;
    polls = [];
    sortInst: any | null = null;
    tabLoading = false;
    resetId: string | null = null;

    refreshTimeout: number | null = null;

    $refs!: {
        optionfields: Element
    };

    openPollDialog() {
        this.showEditor = true;
        this.$nextTick(() => {
            /* eslint-disable */
            let _self = this;
            this.sortInst = Sortable.create(this.$refs.optionfields, {
                onEnd({newIndex, oldIndex}) {
                    let opts = [..._self.options];
                    let row = opts.splice(oldIndex, 1)[0];
                    opts.splice(newIndex, 0, row);
                    _self.options = [];
                    _self.$nextTick(() => { _self.options = opts;})
                    _self.$forceUpdate();
                },
                handle: '.draghandle'
            })
        });
    }

    closePollDialog() {
        this.pollId = null;
        this.question = "";
        this.options = [""];
        this.sortInst.destroy();
        this.showEditor = false;
    }

    async fetchPolls(silent = false) {
        if (!silent) this.tabLoading = true;
        let resp = await http.get(`/admin/streams/${this.$route.params.stream}/poll`);
        this.polls = resp.data.data;
        if (!silent) this.tabLoading = false;

    }

    editPoll(poll) {
        this.pollId = poll.id;
        this.question = poll.title;
        this.options = [...poll.options.map(x => x.title)];
        this.openPollDialog();
    }

    async savePoll() {
        this.tabLoading = true;
        let url = `/admin/streams/${this.$route.params.stream}/poll`;
        if (this.pollId)
            url += `/${this.pollId}`;
        let resp = await http.post(url, {
            title: this.question,
            options: this.options,
            _method: this.pollId ? 'PUT' : 'POST'
        });
        await this.fetchPolls();
        this.closePollDialog();
        this.tabLoading = false;
    }

    async deletePoll(poll) {
        await http.post(`/admin/streams/${this.$route.params.stream}/poll/${poll.id}`, {_method: 'DELETE'});
        await this.fetchPolls();
    }

    async resetPoll() {
        this.tabLoading = true;
        await http.post(`/admin/streams/${this.$route.params.stream}/poll/${this.resetId}/reset`);
        await this.fetchPolls(true);
        this.resetId = null;
        this.tabLoading = false;
    }

    async mounted() {
        await this.fetchPolls();
        //this.refreshTimeout = setInterval(async() => {
       //     if (!this.showEditor)
        //        await this.fetchPolls(true)
        //}, 5000);
    }

    beforeDestroy() {
        if (this.refreshTimeout)
            clearTimeout(this.refreshTimeout);
        this.refreshTimeout = null;
    }

}
