














































































import {inverseTextColor, idToColor} from "@/util/colorlib";
import Vue from "vue";
import { Component, Prop, Watch } from 'vue-property-decorator'
import {ChatMessage, Chatroom, StreamParticipant} from "@/types/stream";

@Component
export default class ChatWindow extends Vue {

    @Prop({type: Object as () => Chatroom})
    public room!: Chatroom;

    @Prop({type: Boolean, default: true})
    public closable!: boolean;

    @Prop({type: Boolean, default: undefined})
    public hideToolbar!: boolean;

    get messages() {
        if (!this.room)
            return [];
        return this.room.messages!;
    }

    scrollToBottom() {
        this.$nextTick(() => {
            let container = (this.$refs.chatContainer as Vue);
            if (container && container.$el)
                container.$el.scrollTop = container.$el.scrollHeight;
        })

    }

    @Watch('messages', { immediate: true, deep: true })
    onMessagesChanged(val: ChatMessage[], oldVal: ChatMessage[]) {
        this.scrollToBottom();
    }

    public message = "";

    async sendMessage() {
        let message = this.message;
        if (message != "" && message.length <= 1000) {
            this.message = "";
            await this.$store.dispatch('chat/sendMessage', message);
        }
    }

    async sendRead() {
        await this.$store.dispatch('chat/sendMessagesRead');
    }

    shouldGroupMessage(idx: number): boolean {
        if (idx - 1 < 0) return false;
        if (idx === this.messages.length - this.room.state.unread) return false;
        return this.messages[idx - 1].from.id === this.messages[idx].from.id;
    }

    avatarText(name: string): string {
        return name.substring(0, 2).toUpperCase();
    }

    avatarColor(id: string): string {
        return idToColor(id);
    }

    avatarTextColor(id: string): string {
        return inverseTextColor(idToColor(id), '#fff', '#000');
    }

    get chatLoading() { return this.$store.getters['chat/loading']; }
}
