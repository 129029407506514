

















import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component
export default class ColorInput extends Vue {

    menu = false

    @Prop({type: String}) readonly label!: string;
    @Prop({type: String, default: '#FF0000'}) readonly value!: string;

    get swatchStyle() {
        const { value, menu } = this
        return {
            backgroundColor: value,
            cursor: 'pointer',
            height: '30px',
            width: '30px',
            borderRadius: menu ? '50%' : '4px',
            transition: 'border-radius 200ms ease-in-out'
        }
    }
}
