import Vue from 'vue'
import Vuex from 'vuex'
import chat from "@/store/ChatModule";
import stream from "@/store/StreamModule";
import auth from "@/store/AuthModule";
import message from "@/store/MessageModule";
import poll from "@/store/PollModule";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
      chat,
      stream,
      auth,
      message,
      poll
  }
})
