var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dashboard-layout',[_c('h1',{staticClass:"display-1"},[_vm._v("Streams")]),_c('v-dialog',{attrs:{"max-width":"640px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi mdi-plus")]),_vm._v(" Nieuw")],1)]}}]),model:{value:(_vm.newDialog),callback:function ($$v) {_vm.newDialog=$$v},expression:"newDialog"}},[_c('v-overlay',{attrs:{"value":_vm.newLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-card',[_c('v-card-title',[_vm._v("Nieuwe Stream")]),_c('v-card-text',[_c('stream-form',{model:{value:(_vm.newStream),callback:function ($$v) {_vm.newStream=$$v},expression:"newStream"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v("Sluiten")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.submit}},[_vm._v("Opslaan")])],1)],1)],1),_c('server-sided-table',{ref:"table",attrs:{"url":"/admin/streams","headers":_vm.headers,"dense":""},on:{"click:row":_vm.browseTo},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"target":"_blank","href":item.url,"x-small":""}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi mdi-open-in-new")]),_vm._v(" User ")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"target":"_blank","href":item.op_url,"x-small":""}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi mdi-open-in-new")]),_vm._v(" Operator ")],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function (e) { e.stopPropagation(); _vm.deleteItem = item }}},[_vm._v("mdi-delete")])]}}])}),_c('v-dialog',{attrs:{"value":_vm.deleteItem != null,"max-width":"640px"},on:{"input":function($event){_vm.deleteItem = null}}},[_c('v-card',[_c('v-card-title',[_vm._v("Verwijder stream")]),_c('v-card-text',[_vm._v("Weet je zeker dat je deze stream wilt verwijderen? Alle bijbehorende gegevens worden vernietigd!")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteItem = null}}},[_vm._v("Annuleren")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.doDelete}},[_vm._v("Verwijder")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }