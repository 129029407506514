import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {OPPoll, OPPollQuestion, Poll} from "@/types/stream";
import {getEcho} from "@/plugins/echo";
import {http} from "@/plugins/axios";
import Vue from "vue";

@Module({namespaced: true})
export default class PollModule extends VuexModule {
    _streamId: string | null = null;
    _polls: OPPoll[] = [];
    _currentPoll: Poll | null = null;

    @Mutation
    setStreamId(streamId) {
        this._streamId = streamId;
    }

    @Mutation
    setPolls(polls: OPPoll[]) {
        this._polls = polls;
    }

    @Mutation
    setPollLaunched(poll: OPPoll) {
        const p = this._polls.find(x => x.id === poll.id);
        if (p) {
            Vue.set(p, 'launched', true);
            Vue.set(p, 'executed', true);
        }
    }

    @Mutation
    LAUNCH_POLL(poll) {
        this._currentPoll = poll;
    }

    @Mutation
    POLL_CREATED(poll) {
        this._polls.push(poll);
    }

    @Mutation
    POLL_UPDATED(poll) {
        const p = this._polls.findIndex(x => x.id === poll.id);
        if (p !== -1) {
            this._polls.splice(p, 1);
            this._polls.splice(p , 0, poll);
        }
    }

    @Mutation
    POLL_DELETED(pollId) {
        this._polls = this._polls.filter(x => x.id !== pollId);
    }


    @Action({rawError: true})
    async init(streamId) {
        this.context.commit('setStreamId', streamId);
        if (this.context.rootGetters['stream/isOperator']) {
            await this.context.dispatch('fetchPolls');
            getEcho()
                .channel(`stream.${streamId}`)
                .listen('PollCreated', (data) => this.context.commit('POLL_CREATED', data.state))
                .listen('PollUpdated', (data) => this.context.commit('POLL_UPDATED', data.state))
                .listen('PollDeleted', (data) => this.context.commit('POLL_DELETED', data.state))
        } else {
            getEcho()
                .channel(`stream.${streamId}`)
                .listen('PollLaunched', (data) => this.context.commit('LAUNCH_POLL', data.state));
        }
    }

    @Action({rawError: true})
    async launch(poll: OPPoll) {
        //try {
            await http.post(`polls/${poll.id}/launch`);
            this.context.commit('setPollLaunched', poll);
        //} catch (e) {
            //
        //}
    }

    @Action({rawError: true})
    async fetchPolls() {
        const resp = await http.get(`polls`);
        this.context.commit('setPolls', resp.data.data);
    }

    @Action({rawError: true})
    async vote(answer) {
        const authData = this.context.rootGetters['stream/authData'];
        const poll = this.context.getters['currentPoll'];
        await http.post(`polls/${poll.id}/vote`, {answer});
        this.context.commit('LAUNCH_POLL', null);
    }

    @Action({rawError: true})
    async clear() {
        this.context.commit('LAUNCH_POLL', null);
    }

    get streamId() { return this._streamId; }

    get polls() { return this._polls; }

    get currentPoll() { return this._currentPoll; }

}
