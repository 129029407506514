










import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
@Component
export default class CopyButton extends Vue {
    @Prop({type: String}) readonly content!: string;

    copyText(on, ev) {
        navigator.clipboard.writeText(this.content).then(function () {
            on.click(ev);
        }, function () {
            //
        });
    }
}
