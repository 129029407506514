














import BaseFormComponent from "../base/BaseFormComponent";
import Component from "vue-class-component";

@Component
export default class StreamForm extends BaseFormComponent {

}
