import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import WatchStream from '../views/WatchStream.vue'
import Login from "@/views/Login.vue";
import Dashboard from "@/views/admin/Dashboard.vue";
//import ViewStream from "@/views/admin/stream/ViewStream.vue";
import ViewStream2 from "@/views/admin/stream/ViewStream2.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            auth: true
        },
    },
    {
        path: '/dashboard/:stream',
        name: 'ViewStream',
        component: ViewStream2,
        meta: {
            auth: true
        },
    },
  {
    path: '/:stream',
    name: 'Home',
    component: WatchStream
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')

    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        next('/login')
        return
    }
    next()
})

export default router
