








































































































































import Vue from "vue";
import Component from "vue-class-component";
import ServerSidedTable from "@/components/ServerSidedTable.vue";
import {Prop} from "vue-property-decorator";
import {Stream} from "@/types/acp";
import {http} from "@/plugins/axios";
import ParticipantForm from "@/components/forms/ParticipantForm.vue";
import CopyButton from "@/components/CopyButton.vue";
@Component({
    components: {ServerSidedTable, ParticipantForm, CopyButton}
})
export default class ParticipantsTab extends Vue {
    participantTableHeaders = [
        {text: "Voornaam", value: 'firstname'},
        {text: "Achternaam", value: 'lastname'},
        {text: "E-mailadres", value: 'email'},
        {text: "Bron", value: 'source'},
        {text: "", value: 'url'},
    ]
    deleteParticipantId: null | number = null;
    newParticipant = null;
    newParticipantDialog = false;
    newLoading = false;

    showChatResetDialog = false;

    importDialog = false;
    importFile: null | File = null;

    inviteDialog = false;
    inviteForce = false;

    meta: null | any =null;
    showSendSnack = false;
    metaTimer = 0;

    @Prop({type: Object}) readonly stream!: Stream;

    $refs!: {
        import: HTMLInputElement,
        participantTable: ServerSidedTable
    }

    get progress() {
        return this.meta.invites_sent / this.meta.count * 100;
    }

    get showSnack() {
        return this.showSendSnack && this.progress !== 100;
    }

    async getMeta() {
        const resp = await http.get(`/admin/streams/${this.stream!.id}/participants/meta`);
        this.meta = resp.data;
    }

    async mounted() {
        this.metaTimer = setInterval(() => this.getMeta(), 1000);
        await this.getMeta();
    }

    async sendInvites() {
        await http.post(`/admin/streams/${this.stream!.id}/invite`, {force: this.inviteForce ? "1" : "0"});
        //await this.$store.dispatch('message/showSnackbar', {color: 'success', message: 'Uitnodigingen zijn verstuurd'});
        this.inviteForce = false;
        this.inviteDialog = false;
        this.showSendSnack = true;
    }

    async deleteChats() {
        await http.post(`/admin/streams/${this.stream!.id}/deletechat`);
        this.showChatResetDialog = false;
    }

    async sendInvite(participantId) {
        await http.post(`/admin/streams/${this.stream!.id}/participants/${participantId}/invite`);
        await this.$store.dispatch('message/showSnackbar', {color: 'success', message: 'Uitnodiging is verstuurd'});
    }

    async submit() {
        this.newLoading = true;
        await http.post(`/admin/streams/${this.stream!.id}/participants`, this.newParticipant);
        this.newLoading = false;
        this.closeDialog();
        await this.$refs.participantTable!.refresh();
        await this.$store.dispatch('message/showSnackbar', {color: 'success', message: 'Nieuwe deelnemer is aangemaakt!'});
    }

    async doImport() {
        const fd = new FormData();
        fd.append("import", this.importFile!);
        await http.post(`/admin/streams/${this.stream!.id}/participants/import`, fd, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        await this.$refs.participantTable.refresh();
        await this.$store.dispatch('message/showSnackbar', {color: 'success', message: 'Importeren is gelukt!'});
        this.importDialog = false;
    }

    async doExport() {
        const response = await http.post(`/admin/streams/${this.$route.params.stream}/participants/export`, {}, {
            responseType: "blob"
        });
        const fileName = response.headers['content-disposition'].replace("attachment; filename=", "").replace(/"/g, "");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    closeDialog() {
        this.newParticipant = null;
        this.newParticipantDialog = false;
    }
    async deleteParticipant() {
        await http.post(`/admin/streams/${this.stream!.id}/participants/${this.deleteParticipantId}`, {
            _method: 'DELETE'
        });
        await this.$refs.participantTable.refresh();
        await this.$store.dispatch('message/showSnackbar', {color: 'success', message: 'Deelnemer is verwijderd!'});
        this.deleteParticipantId = null;
    }

}
