



























































import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator'
import {Chatroom, StreamParticipant} from "@/types/stream";
import {idToColor, inverseTextColor} from "@/util/colorlib";

@Component
export default class ChatList extends Vue {
    @Prop({type: Array as () => Chatroom[]})
    public rooms!: Chatroom[]

    avatarText(name: string): string {
        return name.substring(0, 2).toUpperCase();
    }

    avatarColor(id: string): string {
        return idToColor(id);
    }

    avatarTextColor(id: string): string {
        return inverseTextColor(idToColor(id), '#fff', '#000');
    }
}
