




import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component
export default class DeleteButton extends Vue {
    clickedOnce = false;
    timeoutHandler: number | null = null;
    @Prop({type: Object}) readonly value!: any;

    clicked() {
        if (this.clickedOnce) {
            this.$emit('confirm', this.value);
            this.clearTimeout();
            this.clickedOnce = false;
        } else {
            this.clickedOnce = true;
            this.timeoutHandler = setTimeout(() => {
                this.clickedOnce = false;
                (this.$refs.btn.$el as HTMLElement).blur();
            }, 2000);
        }
    }

    clearTimeout() {
        if (this.timeoutHandler)
            clearTimeout(this.timeoutHandler);
        this.timeoutHandler = null;
    }

    $refs!: {
        btn: Vue,
    }

    get icon() {
        return this.clickedOnce ? "mdi-delete-forever" : "mdi-delete";
    }

    get color() {
        return this.clickedOnce ? "red darken-3" : "red";
    }

    get iconClasses() {
        return this.clickedOnce ? ['shake'] : []
    }
}
