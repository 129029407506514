















import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {http} from "../plugins/axios";

@Component
export default class ServerSidedTable extends Vue {
    items = [];
    loading = false;

    serverItemsLength = 0;

    opts = {page: 1};

    @Prop({type: String, default: () => ""}) readonly url!: string;

    async refresh() {
        this.loading = true;
        let resp = await http.get(this.url, {
            params: {
                page: this.opts.page
            }
        });
        this.items = resp.data.data;
        this.serverItemsLength = resp.data.length;
        this.loading = false;
    }

    async mounted() {
        await this.refresh();
    }


}
