<template>
    <v-form @submit.prevent="$emit('submit')">
        <v-container>
            <v-row>
                <v-col>
                    <v-text-field :value="v.firstname" @input="update('firstname', $event)" label="Voornaam" hide-details single-line></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field :value="v.lastname" @input="update('lastname', $event)" label="Achternaam" hide-details single-line></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field :value="v.email" @input="update('email', $event)" label="E-mailadres" hide-details single-line></v-text-field>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import Vue from "vue";
import BaseFormComponent from "../base/BaseFormComponent";
import Component from "vue-class-component";

@Component
export default class ParticipantForm extends BaseFormComponent {

}
</script>

<style scoped>

</style>
