










































































import Vue from "vue";
import Component from "vue-class-component";
import TagChecker from "@/components/TagChecker.vue";
import {Prop} from "vue-property-decorator";
import {Stream} from "@/types/acp";
import {http} from "@/plugins/axios";

@Component({
    components: {TagChecker}
})
export default class EmailTab extends Vue {
    text = "# Hello world";
    tab = 0;
    helpDialog = false;
    @Prop({type: Object}) readonly stream!: Stream;
    previewData = "";

    async refreshEmail() {
        let resp = await http.post(`/admin/streams/${this.stream!.id}/preview`, {
            content: this.stream.invite_mail_body
        });
        this.previewData = resp.data;
    }
}
