import Echo from 'laravel-echo'
import * as pusher from 'pusher-js';
import _Vue from "vue";

window.Pusher = pusher;

let _echo: null | any = null;

function getEcho() {
    if (!_echo) {
        _echo = new Echo({
            broadcaster: 'pusher',
            key: "361959a9196580441e25d41e5b0e438b",
            wsHost: window.location.hostname,
            wsPort: 6001,
            wssHost: window.location.hostname,
            wssPort: 6001,
            forceTLS: false,
            disableStats: true,
            enabledTransports: ['ws', 'wss']
        });
    }
    return _echo;
}

function EchoPlugin(Vue: typeof _Vue) {
    Object.defineProperties(Vue.prototype, {
        echo: {
            get() {
                return getEcho();
            }
        },
        $echo: {
            get() {
                return getEcho();
            }
        },
    });
}

_Vue.use(EchoPlugin);

export {getEcho};
