














































































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class DashboardLayout extends Vue {

    nav = [
        {icon: 'mdi-broadcast', text: 'Streams', to: {name: 'Dashboard'}},
    ];

    get user() { return this.$store.getters['auth/user']; }

    logout() {
        return this.$store.dispatch('auth/logout');
    }

    async hideSnackbar() {
        return await this.$store.dispatch('message/hideSnackbar');
    }

    get snackbar() {
        return this.$store.getters['message/getSnackbar'];
    }

    get hasSnackbar() {
        return this.$store.getters['message/hasSnackbar'];
    }

}
