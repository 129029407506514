




import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component
export default class TagChecker extends Vue {
    @Prop({type: String}) readonly value!: string;
    @Prop({type: String}) readonly tag!: string;

    get spanClasses() {
        const valueContainsTag = this.value?.includes(this.tag);
        return {
            "black--text": !valueContainsTag,
            "text--disabled": valueContainsTag,
        }
    }
}
