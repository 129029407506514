




















































































































































































import Component from "vue-class-component";
import Vue from "vue";
import DashboardLayout from "@/layouts/DashboardLayout.vue";
import StreamForm from "@/components/forms/StreamForm.vue";
import ParticipantsTab from "@/views/admin/stream/tabs/ParticipantsTab.vue";
import {http} from "@/plugins/axios";
import ColorInput from "@/components/ColorInput.vue";
import EmailTab from "@/views/admin/stream/tabs/EmailTab.vue";
import {Stream} from "@/types/acp";
import makeFormData from "json-form-data";
import CopyButton from "@/components/CopyButton.vue";
import PollTab from "@/views/admin/stream/tabs/PollTab.vue";
import ElementsTab from "@/views/admin/stream/tabs/ElementsTab.vue";
@Component({
    components: {ElementsTab, PollTab, CopyButton, EmailTab, ColorInput, ParticipantsTab, DashboardLayout, StreamForm}
})
export default class ViewStream2 extends Vue {
    stream: null | Stream = null
    loading = true
    logoPreview: string | null = null

    async loadData() {
        this.loading = true;
        let resp = await http.get(`/admin/streams/${this.$route.params.stream}`);
        this.stream = resp.data;
        this.loading = false;
    }

    async mounted() {
        await this.loadData();
    }

    makePreview(e) {
        const [file] = e.target.files;
        if (file) {
            this.logoPreview = URL.createObjectURL(file);
            this.stream!.logo = file;
        }
    }

    async doExport() {
        const response = await http.post(`/admin/streams/${this.$route.params.stream}/export`, {}, {
            responseType: "blob"
        });
        const fileName = response.headers['content-disposition'].replace("attachment; filename=", "").replace(/"/g, "");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    async save() {
        try {
            this.loading = true;
            let data = makeFormData({
                ...this.stream,
                statistics: undefined,
                _method: 'PUT'
            }, {
                initialFormData: new FormData()
            });
            let resp = await http.post(`/admin/streams/${this.$route.params.stream}`, data);
            await this.loadData();
            await this.$store.dispatch('message/showSnackbar', {
                color: 'success',
                message: 'Wijzigingen zijn opgeslagen!'
            });
        } catch (e) {
            await this.$store.dispatch('message/showSnackbar', {
                color: 'error',
                message: 'Kan wijzigingen niet opslaan op dit moment. Probeer het later nog eens.'
            });
        } finally {
            this.loading = false;
        }
    }

    get logo() {
        if (this.logoPreview)
            return this.logoPreview;
        if (this.stream!.logo)
            return this.stream!.logo;
        return 'https://laravel.com/img/notification-logo.png';
    }

}
