

































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Login extends Vue {
    email = '';
    password = '';
    loading = false;

    async login() {
        this.loading = true;
        try {
            await this.$store.dispatch('auth/login', {
                email: this.email,
                password: this.password
            });
            await this.$router.push({name: 'Dashboard'});
        } catch (e) {
            if (e.response.status === 422) {
                if (e.response.data.error) {
                    //await this.$store.dispatch('message/showMessage', {text: e.response.data.error, color: 'error'});
                }
            }
        } finally {
            this.loading = false;
        }
    }
}
