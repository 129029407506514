import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import "@/plugins/echo";
import "@/plugins/youtube-embed";
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
    created() {
      const userInfo = localStorage.getItem('user');
      if (userInfo) {
          const userData = JSON.parse(userInfo);
          this.$store.commit('auth/setUserData', userData);
      }
    }
}).$mount('#app');

