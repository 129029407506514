






import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class App extends Vue {
    //async mounted() {
    //    await this.$store.dispatch("chat/initSocket");
    //}
}
