import Vue from "vue";
import {Prop} from "vue-property-decorator";

export default class BaseFormComponent extends Vue {

    @Prop({type: Object}) readonly value;

    get v() {
        return this.value ? this.value : {}
    }

    update(key, value) {
        this.$emit('input', { ...this.value, [key]: value })
    }

}
