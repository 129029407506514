import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import {http} from "@/plugins/axios";
import {StreamParticipant} from "@/types/stream";

export type StreamData = {
    title: string,
    embed_url: string,
    id: string,
    hoster: string,
    theme_color: string,
    logo: string,
    isPublic: boolean,
    isEmailRequired:boolean
};

@Module({namespaced: true})
export class StreamModule extends VuexModule {
    title = "";
    embed_url = "";
    id = "";
    hoster = "";
    theme_color = "";
    logo = "";
    isPublic = false;
    isEmailRequired = true;

    participantData: StreamParticipant | null = null;

    @Mutation
    setStreamData({title, embed_url, id, hoster, theme_color, logo, isPublic, isEmailRequired}: StreamData) {
        this.title = title;
        this.embed_url = embed_url;
        this.id = id;
        this.hoster = hoster;
        this.theme_color = theme_color;
        this.logo = logo;
        this.isPublic = isPublic;
        this.isEmailRequired = isEmailRequired;
    }

    @Mutation
    setParticipantData(data: StreamParticipant) {
        this.participantData = data;
    }

    @Action
    async loadStreamData({streamId}: {streamId: string}) {
        try {
            const resp = await http.get(`streams/${streamId}`);
            resp.data.data.isPublic = resp.data.data.public; //sorry hack
            resp.data.data.isEmailRequired = resp.data.data.email_required; //sorry hack
            this.context.commit('setStreamData', resp.data.data);
            return resp.data.data;
        } catch(e) {
            return null;
        }
    }

    @Action({rawError: true})
    async authorize({key, streamId}: {key: string, streamId: string}) {
        try {
            const resp = await http.post(`streams/${streamId}/authorize`, {key});
            if (resp.status != 200)
                return null;
            http.defaults.headers.common.Authorization = `Bearer ${resp.data.token}`;
            this.context.commit('setParticipantData', resp.data.data);
            await this.context.dispatch("chat/init", null, {root: true});
            await this.context.dispatch("poll/init", streamId, {root: true});
            return resp.data.data;
        } catch(e) {
            if (e.response) {
                if (e.response.status === 403)
                    localStorage.removeItem("keydata");
            }
        }
    }

    @Action({rawError: true})
    async joinAsNewParticipant({streamId, firstname, lastname, email}: {streamId: string, firstname: string, lastname: string, email: string}) {
        const resp = await http.post(`streams/${streamId}/join`, {firstname, lastname, email});
        if (resp.status != 200)
            return false;
        localStorage.setItem("keydata", JSON.stringify({
            streamId,
            key: resp.data.key
        }));
        await this.context.dispatch("authorize", {key: resp.data.key, streamId});
        return true;
    }

    get streamData() {
        return {
            title: this.title,
            embed_url: this.embed_url,
            id: this.id,
            hoster: this.hoster,
            theme_color: this.theme_color,
            logo: this.logo,
            isPublic: this.isPublic,
            isEmailRequired: this.isEmailRequired
        }
    }

    get authorized() {
        return this.participantData !== null;
    }

    get authData() {
        return this.participantData;
    }

    get isOperator() {
        if (this.participantData === null)
            return false;
        return this.participantData.operator;
    }

}

export default StreamModule;
